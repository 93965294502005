import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SEO from '../components/SEO';
import StaticContent from '../components/CompanyProfile/StaticContent';

import '../components/TXLandingPage/index.css';
import '../components/Default/index.css';

import LandingSmarterChoice from '../components/LandingSC';

import * as webSocketActions from '../redux/actions/webSocketActions';

function DefaultPage({ actions, serviceAddress, siteConfig, webSocket }) {
  return siteConfig.id === 'sc' ? (
    <LandingSmarterChoice />
  ) : (
    <>
      <SEO
        title={'Welcome'}
        description={
          'Online ‘new resident’ onboarding platform that assists soon-to-be new residents with their upcoming move by helping them effectively manage the entire process, step-by-step, in the most efficient and least stressful way possible. Benefits residents and onsite teams as well by automating incoming resident tracking, pre-move in compliance status, and document retrieval and management.'
        }
        brand={'mi'}
      />

      <StaticContent includeIntro includeLogin />
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
